<template>
  <div class="d-block text-center">
    <div class="bookingfaildthimble">
      <img src="/assets/sabre-img/bookingfailed.jpg" alt="#">
    </div>
    <h2>{{$t("sabre.booking.something-went-wrong")}}<br> {{$t("sabre.booking.no-able-message")}} </h2>
    <a href="#" class="btn btn-warning m-0">{{$t("sabre.buttons.try-again")}}</a>
  </div>
</template>
<script>
export default {

};
</script>
